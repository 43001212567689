import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyDDCbWeVcV1HKjLJMPNOXs3ZxOMyiaZAp0",
  authDomain: "jomann-web.firebaseapp.com",
  databaseURL: "jomann-web",
  projectId: "jomann-web",
  storageBucket: "jomann-web.appspot.com",
  messagingSenderId: "117693241038",
  appId: "117693241038:web:0a6cdb2f6165ab6e04e29f",
  measurementId: "G-NLRNK52641",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
