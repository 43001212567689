import React, { useState, useEffect } from "react";
import fire from "./firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const isAuth = JSON.parse(localStorage.getItem("user"))
  const isToken = localStorage.getItem("token")
  
  useEffect(() => {
    if(isAuth && isToken){
      setCurrentUser(isAuth)
    }
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
